import HeadLine from "../../HeadLine";

export default function Pricing() {
  return (
    <section id="pricing" className="py-16 dark:bg-jacarta-900">
      <HeadLine
        text="Pricing"
        classes="font-display text-jacarta-700 pb-12 text-center text-3xl dark:text-white"
      />

      <div className="container">
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
          <PriceCard price="$125" plan="Standard" month="1 Year Package" />
          <PriceCard price="$225" plan="Premium" month="3 Year Package" />
          <PriceCard price="$350" plan="Platinum" month="5 Year Package" />
        </div>
      </div>
    </section>
  );
}

const PriceCard = ({ price, plan, month }) => {
  return (
    <div className="service-item relative rounded-2.5xl border border-jacarta-100 bg-white p-10 pt-12 transition-shadow hover:shadow-xl dark:border-jacarta-700 dark:bg-jacarta-700">
      <div className="flex items-center justify-center gap-2 mb-6">
        <span className="h-4 w-4 rounded-full bg-[#fff212] dark:bg-[#fff212]"></span>
        <span className="font-display text-lg dark:text-jacarta-200">
          {plan}
        </span>
      </div>
      <div className="flex flex-col items-center mb-8">
        <span className="font-display text-4xl dark:text-white">{price}</span>
        <span className="dark:text-jacarta-400">{month}</span>
      </div>
      <div className="text-center">
        <p className="dark:text-jacarta-200 mb-2">IPTV Free Trial (24 Hours)</p>
        <p className="dark:text-jacarta-200 mb-2">16000+ Channels</p>
        <p className="dark:text-jacarta-200 mb-2">4k Format</p>
        <p className="dark:text-jacarta-200 mb-2">24*7 Sales Support</p>
      </div>
    </div>
  );
};
