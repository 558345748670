// images
import logo from "../../../assets/images/logo.png";
import logoWhite from "../../../assets/images/logo_white.png";

export default function CloudIptvLogo() {
  return <h1 className="font-display text-xl text-[#b8d89a]">Cloud IPTV</h1>;
}
// export default function CloudIptvLogo() {
//   return (
//     <a className="shrink-0">
//       <img
//         src={logo}
//         className="max-h-7 h-auto dark:hidden"
//         alt="Cloud Iptv Logo"
//       />

//       <img
//         src={logoWhite}
//         className="max-h-7 h-auto hidden dark:block"
//         alt="Cloud Iptv Logo"
//       />
//     </a>
//   );
// }
