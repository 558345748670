import React, { useState, useEffect } from "react";

// components
import Menu from "./Menu";
import {
  CloudIptvLogo,
  DesktopMenuAction,
  MobileMenuAction,
} from "./components";

export default function Navbar() {
  const [scroll, setScroll] = useState(false);

  const handleSticky = function () {
    if (window.scrollY >= 100) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleSticky);
  }, []);

  return (
    <header
      className={
        scroll
          ? "js-page-header fixed top-0 z-20 w-full backdrop-blur transition-colors js-page-header--is-sticky"
          : "js-page-header page-header--transparent fixed top-0 z-20 w-full bg-white/[.15] backdrop-blur transition-colors"
      }
    >
      <div className="flex items-center px-6 py-6 xl:px-24">
        <CloudIptvLogo />
        <Menu />
        {/* <DesktopMenuAction /> */}
        {/* <MobileMenuAction /> */}
      </div>
    </header>
  );
}
