import HeadLine from "../../HeadLine";

// images
import hindi from "../../../assets/images/channels/hindi-Channel.webp";
import punjbi from "../../../assets/images/channels/punjbi-channel.webp";
import gujrati from "../../../assets/images/channels/gujrati-channel.webp";
import bangla from "../../../assets/images/channels/bangla-channel.webp";
import urdu from "../../../assets/images/channels/urdu-channel.webp";
import english from "../../../assets/images/channels/english-channel.webp";
import kannad from "../../../assets/images/channels/kannad-channel.webp";
import malyalam from "../../../assets/images/channels/malyalam-channel.webp";
import telgu from "../../../assets/images/channels/telgu-channel.webp";
import nepali from "../../../assets/images/channels/nepali-channel.webp";
import tamil from "../../../assets/images/channels/tamil-channel.webp";
import pakistani from "../../../assets/images/channels/pakistani-channel.webp";

export default function Channels() {
  return (
    <>
      <section id="channels" className="py-16">
        <HeadLine
          text="All Available Channels"
          classes="font-display text-jacarta-700 pb-12 text-center text-3xl dark:text-white"
        />
        <div className="container">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 md:gap-[1.875rem] lg:grid-cols-5">
            {[
              hindi,
              punjbi,
              gujrati,
              bangla,
              pakistani,
              urdu,
              english,
              kannad,
              tamil,
              malyalam,
              nepali,
              telgu,
            ].map((image) => {
              return (
                <div
                  className="dark:bg-jacarta-700 rounded-2lg dark:border-jacarta-600 border-jacarta-100 border bg-white p-4 text-center transition-shadow hover:shadow-lg"
                  key={image}
                >
                  <img
                    src={image}
                    className="rounded-2.5xl mx-auto w-[8.125rem]"
                    alt={""}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}
