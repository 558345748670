const Footer = () => {
  return (
    <>
      {/* <!-- Footer --> */}

      <footer className="dark:bg-jacarta-900 page-footer bg-white">
        <div className="container">
          {/* <!-- Logo --> */}
          {/* <a className="mb-6 inline-block">
                <img
                  src="/images/logo.png"
                  className="max-h-7 dark:hidden"
                  alt="Xhibiter | NFT Marketplace"
                />
              </a> */}

          {/* <a className=" mb-6 inline-block">
            <img
              src="/images/logo_white.png"
              className="hidden max-h-7 dark:block mb-6"
              alt="Xhibiter | NFT Marketplace"
            />
          </a> */}

          <div className="flex justify-center flex-col items-center pt-12">
            <h1 className="font-display text-4xl mb-4">
              <span className="animate-gradient">Cloud IPTV</span>
            </h1>

            <p className="text-center dark:text-jacarta-300 max-w-[300px]">
              Cloud IPTV has a huge database of over 20,000 movies and TV
              channels
            </p>
          </div>

          <div className="flex flex-col items-center justify-between space-y-2 py-8 sm:flex-row sm:space-y-0">
            <span className="dark:text-jacarta-400 text-sm">
              <span>
                © {new Date().getFullYear()} all right reserved - Cloud IPTV
              </span>
            </span>

            <ul className="dark:text-jacarta-400 flex flex-wrap space-x-4 text-sm">
              <li>
                <a className="hover:text-accent dark:hover:text-white">
                  Terms and conditions
                </a>
              </li>
              <li>
                <a className="hover:text-accent dark:hover:text-white">
                  Privacy policy
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;

const footerMenuList = [
  {
    id: 1,
    title: "",
    diffClass: "md:col-start-7",
    list: [],
  },
  {
    id: 2,
    title: "",
    diffClass: "",
    list: [],
  },
  {
    id: 3,
    title: "My Account",
    diffClass: "",
    list: [
      {
        id: 1,
        href: "#",
        text: "Channels",
      },
      {
        id: 2,
        href: "#",
        text: "Pricing",
      },
      {
        id: 3,
        href: "#",
        text: "Downloads",
      },
    ],
  },
];
