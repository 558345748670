import Channels from "../../components/home/Channels";
import Download from "../../components/home/Download";
import Hero from "../../components/home/Hero";
import Pricing from "../../components/home/Pricing";

export default function Home() {
  return (
    <>
      <Hero />
      <Channels />
      <Pricing />
      <Download />
    </>
  );
}
